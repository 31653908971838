//@ts-check
import { ChakraProvider, extendTheme } from "@chakra-ui/react";
import { css, Global } from "@emotion/react";
import "@fontsource/ubuntu";
import "focus-visible/dist/focus-visible";
import React from "react";
import { Helmet } from "react-helmet";
import { QueryClient, QueryClientProvider } from "react-query";
import Layout from "./components/Layout";

const queryClient = new QueryClient();
// 2. Call `extendTheme` and pass your custom values
const theme = extendTheme({
  colors: {
    red: {
      50: "#ffe8ee",
      100: "#f0c4ce",
      200: "#e19fab",
      300: "#d37987",
      400: "#c55461",
      500: "#ab3a43",
      600: "#862c3a",
      700: "#611f2d",
      800: "#3c101d",
      900: "#1c030c",
    },
    somaylar: {
      50: "#ffece4",
      100: "#f0ccc4",
      200: "#dfaca0",
      300: "#d08d7b",
      400: "#c26c57",
      500: "#a8533d",
      600: "#843f2f",
      700: "#5e2d21",
      800: "#3b1a11",
      900: "#1c0500",
    },
  },
});
export const wrapPageElement = ({ element }) => {
  return (
    <QueryClientProvider client={queryClient}>
      <Global
        styles={css`
          #nprogress {
            position: fixed;
            z-index: 100000;
          }
        `}
      />

      <ChakraProvider resetCSS={true} theme={extendTheme(theme)}>
        <Helmet>
          <title>SOMAYLAR OTOMOTİV</title>
          <meta
            name="description"
            content="Somaylar Otomotiv Yedek Parça ve Servis. Firmamızın temeli 1980 yılında babamız Hasan Şevket SOMAY tarafından atıldı. 1987 yılından itibaren Otokar yetkili servisi olarak hizmet vermekteyiz."
          />
          <link rel="apple-touch-icon" sizes="180x180" href="/apple-touch-icon.png" />
          <link rel="icon" type="image/png" sizes="32x32" href="/favicon-32x32.png" />
          <link rel="icon" type="image/png" sizes="16x16" href="/favicon-16x16.png" />
          <link rel="manifest" href="/site.webmanifest" />
        </Helmet>
        <Layout>{element}</Layout>
      </ChakraProvider>
    </QueryClientProvider>
  );
};

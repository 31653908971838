// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-admin-index-jsx": () => import("./../../../src/pages/admin/index.jsx" /* webpackChunkName: "component---src-pages-admin-index-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-products-atlas-jsx": () => import("./../../../src/pages/products/atlas.jsx" /* webpackChunkName: "component---src-pages-products-atlas-jsx" */),
  "component---src-pages-products-doruk-jsx": () => import("./../../../src/pages/products/doruk.jsx" /* webpackChunkName: "component---src-pages-products-doruk-jsx" */),
  "component---src-pages-products-index-jsx": () => import("./../../../src/pages/products/index.jsx" /* webpackChunkName: "component---src-pages-products-index-jsx" */),
  "component---src-pages-products-kent-jsx": () => import("./../../../src/pages/products/kent.jsx" /* webpackChunkName: "component---src-pages-products-kent-jsx" */),
  "component---src-pages-products-m-2010-jsx": () => import("./../../../src/pages/products/m2010.jsx" /* webpackChunkName: "component---src-pages-products-m-2010-jsx" */),
  "component---src-pages-products-sultan-jsx": () => import("./../../../src/pages/products/sultan.jsx" /* webpackChunkName: "component---src-pages-products-sultan-jsx" */),
  "component---src-templates-product-template-jsx": () => import("./../../../src/templates/ProductTemplate.jsx" /* webpackChunkName: "component---src-templates-product-template-jsx" */)
}


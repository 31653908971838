//@ts-check
import { Box, Center, Flex, HStack, Text } from "@chakra-ui/layout";
import { Link } from "gatsby";
import React from "react";

const Layout = ({ children }) => {
  return (
    <Box>
      <Box
        position="fixed"
        top="0"
        left="0"
        right="0"
        bottom="0"
        bg="somaylar.500"
        backgroundImage={`url("data:image/svg+xml,%3Csvg width='42' height='44' viewBox='0 0 42 44' xmlns='http://www.w3.org/2000/svg'%3E%3Cg id='Page-1' fill='none' fill-rule='evenodd'%3E%3Cg id='brick-wall' fill='%23390b00' fill-opacity='0.58'%3E%3Cpath d='M0 0h42v44H0V0zm1 1h40v20H1V1zM0 23h20v20H0V23zm22 0h20v20H22V23z'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E")`}
      ></Box>
      <Box position="relative">
        <Header></Header>
        <Box maxW="1200px" w="100%" minH="100vh" bg="gray.50" mx="auto" position="relative">
          <Box mt={{ base: "0", md: "80px" }}>{children}</Box>
        </Box>
      </Box>
    </Box>
  );
};

const Header = () => {
  const pathname = typeof window !== "undefined" ? window.location.pathname : "";
  return (
    <Box
      id="header"
      // py="4"
      boxShadow="lg"
      bg="white"
      position={{ base: "relative", md: "fixed" }}
      top="0"
      right="0"
      left="0"
      zIndex="overlay"
    >
      <Flex
        maxW="1200px"
        h="80px"
        w="100%"
        mx="auto"
        userSelect="none"
        alignItems="center"
        justifyContent={{ base: "center", md: "space-between" }}
        px="7"
        // fontSize="20px"
      >
        <Logo></Logo>
        <HStack d={{ base: "none", md: "flex" }} spacing="2" p="0">
          <MenuItem to={"/"} isSelected={pathname === "/"} title="ANA SAYFA" />
          <MenuItem
            to={pathname.startsWith("/products") ? pathname : "/products"}
            isSelected={pathname.startsWith("/products")}
            title="YEDEK PARÇA"
          />
        </HStack>
      </Flex>
    </Box>
  );
};

const Logo = () => (
  <Box as={Link} to="/" textAlign="center">
    <Text fontSize={{ md: "2.3rem", base: "1.9rem" }} lineHeight="38px" color="gray.800" fontWeight="800">
      SOMAYLAR
    </Text>
    <Text
      fontSize={{ md: "0.8rem", base: "0.6rem" }}
      fontWeight="bold"
      color="white"
      bg="red.500"
      p="3px"
      rounded="sm"
      // opacity="0.6"
      lineHeight="16px"
      // letterSpacing="-0.5px"
    >
      OTOKAR YEDEK PARÇA • SERVİS
    </Text>
  </Box>
);
const MenuItem = ({ isSelected, title, to }) => {
  return (
    <Center
      as={Link}
      to={to}
      cursor="pointer"
      borderBottomWidth="3px"
      transition="all"
      transitionDuration={"0.1s"}
      transitionTimingFunction="cubic-bezier(.08,.52,.52,1)"
      fontSize="18px"
      fontWeight="700"
      height="80px"
      // py="25px"
      px="13px"
      borderBottomColor={isSelected ? "red.500" : "white"}
      color={isSelected ? "red.500" : "gray.700"}
      _hover={{ borderBottomColor: "red.500", color: "red.500" }}
    >
      {title}
    </Center>
  );
};
export default Layout;
